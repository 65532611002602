/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"

const Location: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 1.75c-4.565 0-8.25 3.737-8.25 8.327 0 1.499.497 3.06 1.183 4.51.691 1.462 1.607 2.88 2.519 4.113a34.151 34.151 0 0 0 2.56 3.067c.36.382.676.694.921.917.12.11.237.21.339.287.05.038.114.084.185.126.035.02.09.05.158.077l.093-.232-.093.232a1.031 1.031 0 0 0 .927-.077c.071-.042.136-.088.185-.126.102-.077.218-.177.339-.287.245-.223.562-.535.922-.917a34.19 34.19 0 0 0 2.56-3.067c.912-1.232 1.828-2.65 2.519-4.112.686-1.451 1.183-3.012 1.183-4.511 0-4.59-3.686-8.327-8.25-8.327Zm-6.295 8.327c0-3.526 2.826-6.37 6.294-6.37 3.47 0 6.296 2.844 6.296 6.37 0 1.092-.37 2.352-.995 3.673-.62 1.31-1.459 2.617-2.323 3.785a32.214 32.214 0 0 1-2.409 2.887c-.214.227-.406.422-.568.58a19.462 19.462 0 0 1-.57-.58 32.205 32.205 0 0 1-2.408-2.887C8.158 16.367 7.32 15.061 6.7 13.75c-.624-1.321-.994-2.58-.994-3.673ZM12 6.377a3.25 3.25 0 1 0-.001 6.5 3.25 3.25 0 0 0 0-6.5Zm-1.366 3.25a1.366 1.366 0 1 1 2.73 0 1.366 1.366 0 0 1-2.73 0Z'
                fill='currentColor'
            />
        </svg>
    )
}
export default Location
